<template>
  <div
    class="ml-3"
    :class="{ 'app-height-global' : loaderState }"
  >
    <Header
      @create-custom-field="onCreateTemplates"
    />
    <Loader
      v-if="loaderState"
      class="pt-0"
    />
    <template v-else>
      <v-card
        v-if="hasTemplates || onFetchDataState"
        class="py-6 px-6 mt-3 app-height-global"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <v-row
          justify="space-between"
          class="align-center"
        >
          <v-col
            cols="12"
            md="auto"
            class="d-flex align-center"
          >
            <SearchComponent
              :search="filter.name"
              :placeholder="$t('placeHolder.searchByName')"
              class="mr-3"
              @update:search="filter.name = $event"
            />

            <FilterDialog
              :data="filter"
              :data-sources="dataSources"
              @update-filter-condition="updateFilterCondition"
            />
          </v-col>

          <v-col
            cols="12"
            md="auto"
          >
            <v-row
              justify="end"
              class="align-center"
            >
              <SettingsMenu 
                table-type="customTemplates"
              />
            </v-row>
          </v-col>
        </v-row>

        <TemplateTable
          :headers="filteredHeaders"
          :items="templates"
          :total="totalTemplates"
          item-key="uid"
          @edit="onEditTemplate"
          @update-pagination="onUpdatePagination"
          @delete="onDeleteTemplate"
          @viewDetail="onViewDetails"
        />
      </v-card>

      <v-card
        v-else
        class="mt-3 app-height-global d-flex align-center justify-center"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <ActiveEmptyState
          :image-src="require('@/assets/png/empty-step.png')"
          image-max-width="323px"
          :title="$t('templatesPage.emptyTitle')"
          :button-text="$t('templatesPage.createTemplate')"
          :is-project-archived="isProjectArchived"
          button-color="primary"
          @button-click="onCreateTemplates"
        />
      </v-card>
    </template>
    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedTemplates"
      :loading.sync="createBtnLoading"
      @create-template="createTemplate"
      @update-template="updateTemplate"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <ViewDetailDialog
      v-model="showViewDetailDialog"
      :data="templateDetails"
      @close-dialog="showCreateUpdateDialog = false"
      @editTemplate="onEditTemplate"
    />

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :template="selectedTemplates"
      @delete="deleteTemplate"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import makeTemplateService from '@/services/api/template';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import Header from '@/components/Admin/CustomTemplates/Header';
import FilterDialog from '@/components/Admin/CustomField/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import TemplateTable from '@/components/Admin/CustomTemplates/TemplateTable.vue';
import CreateUpdateDialog from '@/components/Admin/CustomTemplates/CreateUpdateDialog.vue';
import DeleteConfirmDialog from '@/components/Admin/CustomTemplates/DeleteConfirmDialog.vue';
import ViewDetailDialog from '@/components/Admin/CustomTemplates/ViewDetailDialog.vue';
import { handleNetworkStatusError } from '@/mixins/redirect';
import Loader from '@/components/base/Loader.vue';
import { sleep } from '@/utils/util';
import { mapGetters, mapActions } from 'vuex';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import projectStatus from '@/mixins/projectStatus';

export default {
  name: 'Templates',
  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    TemplateTable,
    CreateUpdateDialog,
    DeleteConfirmDialog,
    Loader,
    ViewDetailDialog,
    ActiveEmptyState,
  },
  mixins: [handleNetworkStatusError, projectStatus],

  data()
  {
    return {
      projectKey: this.$route.params.key,
      handle: this.$route.params.handle,
      loaderState: false,
      templateService: makeTemplateService(this.$api),
      onFetchDataState: false,
      filter: {
        name: '',
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      },
      createBtnLoading: false,
      headers: [],
      originalCustomFields: [],
      filteredCustomFields: [],
      showCreateUpdateDialog: false,
      showDeleteConfirmDialog: false,
      showViewDetailDialog: false,
      selectedTemplates: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
      customFieldService: null,
      templateDetails: null,
      isLoading: false,
      errorMessage: '',
      dataSources: [],
      templates: [],
      totalTemplates: 0,
    };
  },

  computed: {
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders',
    }),
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    filteredMenuHeaders()
    {
      return this.headers.filter((header) => header.text !== 'Actions')
    },
    hasTemplates() {
      return this.totalTemplates > 0;
    },
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function ()
      {
        this.initTemplates();
      }, 500),
    },
  },

  created() {
    if(!this.dynamicHeaders.customTemplates) {
      this.initializeHeaders({ type: 'customTemplates' });
    }
    this.headers = this.dynamicHeaders.customTemplates;
  },
  
  mounted() {
    this.init();
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async getCustomFields()
    {
      this.isLoading = true;
      try {
        const response = await this.customFieldService.getCustomFields(this.handle);
        this.originalCustomFields = response.data;

        this.dataSources = _.uniq(_.map(this.originalCustomFields, 'source'));
      } catch (err) {
        this.isLoading = true;
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },
    async init() {
      this.loaderState = false;
      this.onFetchDataState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
        this.onFetchDataState = false;
      }, 1000);

      try {
        await this.initTemplates();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'templates' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
        };
        hideLoader(); 
        this.onFetchDataState = false;
      }
    },
    async initTemplates(paginationOptions) {
      const searchParams = new URLSearchParams();
      searchParams.set('current_page', paginationOptions ? paginationOptions.page : 1);
      searchParams.set('per_page', paginationOptions ? paginationOptions.itemsPerPage : 10);

      if (this.filter.name) {
        searchParams.set('name', this.filter.name);
      }

      if (this.filter.startDate) {
        searchParams.set('creationStartDate', this.filter.startDate);
      }

      if (this.filter.endDate) {
        searchParams.set('creationEndDate', this.filter.endDate);
      }

      try {
        const response = await this.templateService.getTemplates(this.$route.params.handle, this.projectKey, searchParams.toString());
        this.templates = response.data.templates;
        this.totalTemplates = response.data.total;
      } catch (error) {
        // Use the mixin's method to handle the error
        this.redirectOnError(error.response?.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'templates' });
      }
    },
    onViewDetails(template)
    {
      this.showViewDetailDialog = true;
      this.templateDetails = template;
    },
    onCreateTemplates()
    {
      this.selectedTemplates = {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      };

      this.showCreateUpdateDialog = true;
    },

    async createTemplate(customField)
    {
      try {
        this.createBtnLoading = true;
        const response = await this.templateService.createTemplate(this.handle, this.projectKey, customField);
        this.showCreateUpdateDialog = false;
        showSuccessToast(this.$swal, 'createSuccess', { item: 'templates' });
        this.initTemplates();
      } catch (err) {
        showErrorToast(this.$swal, 'createError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      } finally {
        this.createBtnLoading = false;
      }

      this.filterCustomFields();
    },

    onEditTemplate(template)
    {
      this.selectedTemplates = {
        uid: template.uid || '',
        name: template.name || '',
        dataType: template.dataType || '',
        customFields: template.customFields || [],
      };

      this.showCreateUpdateDialog = true;
    },

    async updateTemplate(template)
    {

      try {
        const response = await this.templateService.updateTemplate(this.handle, this.projectKey, template.uid, template);
        this.initTemplates();
        this.showCreateUpdateDialog = false;
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'templates' });
      } catch (err) {
        showErrorToast(this.$swal, 'updateError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },

    onDeleteTemplate(template)
    {
      this.selectedTemplates = {
        uid: template.uid || '',
        name: template.name || '',
      };

      this.showDeleteConfirmDialog = true;
    },

    async deleteTemplate()
    {
      this.showDeleteConfirmDialog = false;

      try {

        const response = await this.templateService.deleteTemplate(this.handle, this.projectKey, this.selectedTemplates.uid);
        this.initTemplates();
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'templates' });
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      }
    },
    onUpdatePagination(options)
    {
      this.initTemplates(options);
    },

    updateFilterCondition(data)
    {
      this.filter = {
        ...this.filter,
        types: data.types,
        sources: data.sources,
        startDate: data.startDate,
        endDate: data.endDate,
      };

      this.filterCustomFields();
      this.initTemplates()
    },

    filterCustomFields()
    {
      let filteredCustomFields = _.cloneDeep(this.originalCustomFields);

      if (this.filter.name) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.name.includes(this.filter.name));
      }

      if (this.filter.types.length > 0) {
        filteredCustomFields = filteredCustomFields.filter((item) => this.filter.types.includes(item.type));
      }

      if (this.filter.sources.length > 0) {
        filteredCustomFields = filteredCustomFields.filter((item) => this.filter.sources.includes(item.source));
      }

      if (this.filter.startDate) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.createdAt >= this.filter.startDate);
      }

      if (this.filter.endDate) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.createdAt <= this.filter.endDate);
      }

      this.filteredCustomFields = filteredCustomFields;
    },
  },
};
</script>
